import {createRouter,createWebHashHistory, createWebHistory} from 'vue-router'
import index from "vuex";

const routes = [
    {
        path: '/',
        name: 'login',
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/frame',
        name: 'Frame',
        component: () => import('@/views/Menu/Menu.vue'),
        meta: {
            requireAuth: true,
        },
        children: [
            {
                path: '/home',
                name: "home",
                component: () => import('@/views/data/Home/Home.vue'),
                meta: {
                    // roles: ['admin', 'user'],
                    requireAuth: true,
                }
            },
            {
                path: '/NewOrders',
                name: "NewOrders",
                component: () => import('@/views/data/NewOrders/NewOrders.vue'),
                meta: {
                    // roles: ['admin', 'user'],
                    requireAuth: true,
                }
            },
            {
                path: '/Shop',
                name: "Shop",
                component: () => import('@/views/data/Shop/Shop.vue'),
                meta: {
                    // roles: ['admin', 'user'],
                    requireAuth: true,
                }
            },
            {
                path: '/Orders',
                name: "Orders",
                component: () => import('@/views/data/OrdersList/Orders.vue'),
                meta: {
                    // roles: ['admin', 'user'],
                    requireAuth: true,
                }
            },
            {
                path: '/Dictionaries',
                name: "Dictionaries",
                component: () => import('@/views/data/Dictionaries/Dictionaries.vue'),
                meta: {
                    // roles: ['admin', 'user'],
                    requireAuth: true,
                }
            },
        ]
    }
]

const router = new createRouter({
    history:createWebHashHistory(process.env.BASE_URL),
    // history: createWebHistory(),
    routes
})

//前端路由拦截判断。
router.beforeEach((to, from, next) => {
    // if (to.meta.requireAuth) {
    //     console.log(to.meta);
    //     if (sessionStorage.getItem("token") != null ) {
    //         next()
    //     }
    //     else {
    //         next('/')
    //     }
    // } else {
    //     next()
    // }
    next()

})

export default router
