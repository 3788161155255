<template>
<!--  <el-config-provider :locale="locale">-->
    <router-view/>
<!--  </el-config-provider>-->
<!--    <router-view :key="this.$route.fullPath"/>-->

</template>

<script>
const debounce = (fn, delay) => {
  let timer
  return (...args) => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
  constructor(callback) {
    callback = debounce(callback, 200);
    super(callback);
  }
}
</script>


<style lang="scss" >

</style>

