import {createApp} from 'vue'
import App from './App.vue'
import router from './router/router.js'
import store from './store'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/src/index.scss'
import zhCn from 'element-plus/dist/locale/zh-cn.js'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

//阿里巴巴图标
import '@/assets/svg/sd/iconfont.css'
//字体
import "@/assets/fonts/font.scss"

import vue3JsonExcel from "vue3-json-excel"

const app = createApp(App)

app.use(store)
    .use(router)
    .use(ElementPlus,{locale:zhCn})
    .use(vue3JsonExcel)
    .mount('#app')



// elm图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}


// router.beforeEach((to, from, next) => {
//     if (to.meta.roles.includes(role)) {
//         next(); //放行
//     } else {
//         alert(404)
//     }
// });

